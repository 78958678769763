import Vue from 'vue'
import _ from 'lodash'
import { TezosToolkit } from "@taquito/taquito";
import { CONTRACTS, Network } from "@hover-labs/kolibri-js";
import Florencenet from "@hover-labs/kolibri-js/build/src/contracts/florencenet";
import Granadanet from "@hover-labs/kolibri-js/build/src/contracts/granadanet";

let network, nodeURL
if (window.location.hostname === 'localhost' ||
    // window.location.hostname === '127.0.0.1' ||
    window.location.hostname === 'testnet.harbinger.live') {
    network = 'ghostnet'
    nodeURL = 'https://rpc.ghostnet.teztnets.xyz'
    // nodeURL = 'https://florencenet.smartpy.io'
} else if (
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname === 'sandbox.harbinger.live') {
    network = Network.Sandbox
    nodeURL = 'https://sandbox.hover.engineering'
} else {
    network = Network.Mainnet
    nodeURL = 'https://mainnet.api.tez.ie'
}

let state = Vue.observable({
    configs: {
        florencenet: {
            normalizerContract: Florencenet.HARBINGER_NORMALIZER,
            oracleContract: 'KT1PuT2NwwNjnxKy5XZEDZGHQNgdtLgN69i9',
        },
        granadanet: {
            normalizerContract: Granadanet.HARBINGER_NORMALIZER,
            oracleContract: 'KT1ATEmroDSWQ41U2rxsox5dS9E2biZ9ML52',
        },
        ghostnet: {
            normalizerContract: 'KT1ENe4jbDE1QVG1euryp23GsAeWuEwJutQX',
            oracleContract: 'KT1UcwQtaztLSq8oufdXAtWpRTfFySCj7gFM',
        },
        mainnet: {
            normalizerContract: CONTRACTS.MAIN.HARBINGER_NORMALIZER,
            oracleContract: 'KT1Jr5t9UvGiqkvvsuUbPJHaYx24NzdUwNW9',
        },
        sandboxnet: {
            normalizerContract: CONTRACTS.SANDBOX.HARBINGER_NORMALIZER,
            oracleContract: 'KT1WibJ3Jw5mjgdqVjFBUVR5V4Vq2g7w1Uci',
        }
    },
    network,
    nodeURL,
    tezosToolkit: new TezosToolkit(nodeURL),
    wallet: null,
    walletAddress: null,
    oracleData: null,
    pendingTx: null
})

// If we're in a sandbox we need to lower our polling interval
if (network === Network.Sandbox) {
    state.tezosToolkit.setProvider({ config: { confirmationPollingIntervalSecond: 2 } })
}

const sandboxOverrides = localStorage.getItem('sandbox-overrides')
if (network === Network.Sandbox && sandboxOverrides !== null) {
    const newState = JSON.parse(sandboxOverrides)
    state = _.merge(state, newState)
}

export default state
