<template>
  <div>
    <sandbox-override v-if="$store.network === 'sandboxnet'" />
    <router-view/>
  </div>
</template>

<style lang="scss">
  @import './assets/sass/globals';
  @import '~bulma/bulma';
  @import '~animate.css/animate.css';
  @import '~@sweetalert2/theme-bulma/bulma.scss';

  body, html {
    background: #3ebd93;
  }

  .loader{
    &.left-spaced{
      margin-left: 0.5rem;
    }
    &.is-large{
      width: 3rem;
      height: 3rem;
    }
    &.is-medium{
      width: 2rem;
      height: 2rem;
    }
    &.is-primary{
      border: 2px solid #3ebd93;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }

</style>
<script>
import SandboxOverride from "@/components/SandboxOverrides";
export default {
  components: {SandboxOverride}
}
</script>
