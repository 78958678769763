<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeInRight"
      leave-active-class="animate__animated animate__fadeOutRight"
  >
    <div v-if="$store.pendingTx" class="pending-tx is-flex is-align-items-center">
      <div class="loader"></div>
      <div>
        <b class="heading">Pending {{ $store.pendingTx.type }} TX</b>
        <a target="_blank" rel="noopener" :href="txLink()">{{ truncateChars($store.pendingTx.raw.opHash, 15) }}</a>
      </div>
    </div>
  </transition>
</template>

<script>
import {Network} from "@hover-labs/kolibri-js";

export default {
  name: 'PendingTx',
  async mounted(){

  },
  methods: {
    txLink(){
      if (this.$store.network === Network.Sandbox){
        return `https://bcd.hover.engineering/sandboxnet/opg/${this.$store.pendingTx.raw.opHash}`
      } else if (this.$store.network === 'mainnet'){
        return `https://tzkt.io/${this.$store.pendingTx.raw.opHash}`
      } else {
        return `https://${this.$store.network}.tzkt.io/${this.$store.pendingTx.raw.opHash}`
      }
    },
    truncateChars(fullStr, strLen, separator) {
      if (fullStr.length <= strLen) return fullStr;

      separator = separator || '...';

      let sepLen = separator.length,
          charsToShow = strLen - sepLen,
          frontChars = Math.ceil(charsToShow/2),
          backChars = Math.floor(charsToShow/2);

      return fullStr.substr(0, frontChars) +
          separator +
          fullStr.substr(fullStr.length - backChars);
    },
  },
  computed: {

  },
  data(){
    return {
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/sass/globals';
  .pending-tx{
    position: fixed;
    top: 1rem;
    right: 0;
    z-index: 35;
    padding: 1rem;
    background: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
    .loader{
      margin-right: .5rem;
    }
  }
</style>
