<template>
  <div v-if="$store.wallet !== null && $store.walletAddress !== null" class="wallet-connector animate__animated animate__fadeInUp">
    <div class="is-flex is-align-items-center is-justify-content-center">
      <div class="image is-48x48 robot-avatar" v-html="avatarSvg()"></div>
      <p class="wallet-address">
        <a @click="$eventBus.$emit('wallet-reconnect')">{{ truncateChars($store.walletAddress, 18) }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-bottts-sprites';

let avatars = new Avatars(sprites, {});

import { BeaconWallet } from "@taquito/beacon-wallet";
import {BeaconEvent, defaultEventCallbacks} from "@airgap/beacon-sdk";

export default {
  name: 'WalletConnector',
  async mounted(){
    const wallet = new BeaconWallet({
      name: 'Harbinger Update Service',
      iconUrl: 'https://harbinger.live/favicon.ico',
      appUrl: 'https://harbinger.live',
      disableDefaultEvents: true,
      eventHandlers: {
        [BeaconEvent.PAIR_INIT]: {
          handler: defaultEventCallbacks.PAIR_INIT,
        },
        [BeaconEvent.PAIR_SUCCESS]: {
          handler: defaultEventCallbacks.PAIR_SUCCESS,
        },
      },
    })

    this.$eventBus.$on('connect-request', async (activeAccount) => {
      try {
        if (activeAccount === undefined){
          await wallet.requestPermissions({
            network: {
              type: this.$store.network === 'sandboxnet' ? 'custom' : this.$store.network,
              rpcUrl: this.$store.nodeURL
            }
          })
        }

        this.$store.walletAddress = await wallet.getPKH()
        this.$store.wallet = wallet

        this.$store.tezosToolkit.setWalletProvider(wallet)
      } catch(e) {
        console.error(e)
      }
    })

    this.$eventBus.$on('wallet-reconnect', async () => {
      this.$store.wallet = null
      await wallet.client.clearActiveAccount()
      this.$eventBus.$emit('connect-request')
    })

    // Go check for an active account, and if it exists just use that
    const activeAccount = await wallet.client.getActiveAccount()
    console.log("Active Account - ", activeAccount)
    if (activeAccount !== undefined){
      this.$eventBus.$emit('connect-request', activeAccount)
    }

    await this.fetchOracleData()
    this.$eventBus.$emit('oracle-init')
    setInterval(this.fetchOracleData, 60 * 1000)
  },
  methods: {
    async fetchOracleData(){
      console.log("Fetching oracle data")
      const response = await fetch("https://oracle-data.kolibri.finance/data.json")
      this.$store.oracleData = await response.json()
    },
    avatarSvg(){
      return avatars.create(this.$store.walletAddress, {
        width: 48,
        height: 48,
        margin: 4,
      })
    },
    truncateChars(fullStr, strLen, separator) {
      if (fullStr.length <= strLen) return fullStr;

      separator = separator || '...';

      let sepLen = separator.length,
          charsToShow = strLen - sepLen,
          frontChars = Math.ceil(charsToShow/2),
          backChars = Math.floor(charsToShow/2);

      return fullStr.substr(0, frontChars) +
          separator +
          fullStr.substr(fullStr.length - backChars);
    },
  },
  computed: {

  },
  data(){
    return {
      networkLoading: false,
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/sass/globals';
  .wallet-connector{
    position: fixed;
    right: 0;
    bottom: 0;
    border-top-left-radius: 5px;
    border: 1px solid white;
    border-bottom: 0; border-right: 0;
    z-index: 35;
    .connect-button{
      margin: .75rem .8rem;
    }
    .robot-avatar{
      margin: .5rem;
    }
    .wallet-address{
      padding-right: 1rem;
      a{
        color: white;
        font-weight: bold;
        &:hover, &:active{
          color: $grey-dark;
        }
      }
    }
  }
</style>
