import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

import Sparkline from 'vue-sparklines'
Vue.use(Sparkline)

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

Vue.prototype.$store = store
Vue.prototype.$eventBus = new Vue()

window.app = new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
