<template>
  <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
  >
    <div>
      <div :class="{'is-active': opened}" class="sandbox-override modal">
        <div @click="opened = false" class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Update Overrides</p>
            <button class="delete" aria-label="close"></button>
          </header>
          <section class="modal-card-body">
            <textarea class="textarea" v-model="sandboxOverride" />
          </section>
          <footer class="modal-card-foot">
            <button @click="activateChanges" class="button is-primary">Activate</button>
            <button @click="opened = false" class="button">Cancel</button>
          </footer>
        </div>
      </div>
      <div class="opener">
        <p>Overriding
          <span v-if="initialSandboxProps && initialSandboxProps !== 'null'">
            {{ Object.keys(JSON.parse(initialSandboxProps)).length }}
          </span>
          <span v-else>0</span>
          Properties <a class="button is-small is-primary" @click="opened = true">Edit</a></p>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SandboxOverride',
  async mounted(){
  },
  methods: {
    activateChanges(){
      try {
        if (this.sandboxOverride.trim() === '' || this.sandboxOverride === null){
          localStorage.setItem('sandbox-overrides', null)
        } else {
          localStorage.setItem('sandbox-overrides', JSON.stringify(JSON.parse(this.sandboxOverride), null, 2))
        }
        location.reload()
      } catch(e) {
        alert("Error setting override! ", e.toString())
      }
    }
  },
  computed: {

  },
  data(){
    return {
      opened: false,
      sandboxOverride: localStorage.getItem('sandbox-overrides'),
      initialSandboxProps: localStorage.getItem('sandbox-overrides'),
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/sass/globals';
  .sandbox-override{
    .modal-card{
      height: 90vh;
    }
    textarea{
      height: 100%;
    }
  }
  .opener{
    position: fixed;
    left: 0;
    bottom: 0;
    background: white;
    padding: 1rem 1rem;
    border-top-right-radius: 5px;
    z-index: 1;
  }
</style>
