<template>
  <section class="section index">
    <pending-tx />

    <div v-if="$store.oracleData !== null" :class="{'is-active': submitPricesModalActive}" class="modal">
      <div @click="submitPricesModalActive = false" class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">New Oracle Data</p>
          <button @click="submitPricesModalActive = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <p>Last Updated: {{ humanFormatEpoch($store.oracleData.timestamp) }}</p>
          <p class="help"><b>Note: </b> The "Last Updated" time is the most recent "close" timestamp returned by harbinger from any of its pairs, and <b>not</b> the timestamp for when the contracts were updated.</p>
          <br>
          <div><pre v-html="oracleDataDisplay"></pre></div>
        </section>
        <section class="modal-card-foot buttons is-right">
          <button :disabled="networkLoading || Object.keys(oracleUpdateRaw.formattedRequest).length === 0" :class="{'is-loading': networkLoading}" @click="updatePrices" class="button is-primary">Submit New Prices</button>
        </section>
      </div>
    </div>

    <wallet-connector />

    <div class="container">
      <h1 class="title has-text-white">Harbinger Price Oracle</h1>
      <h2 class="subtitle has-text-white">DeFi for the Tezos ecosystem. <a target="_blank" rel="noopener" href="https://github.com/tacoinfra/harbinger#harbinger" class="button is-outlined is-white is-small">Learn More</a></h2>
      <div class="columns is-centered">
        <div v-if="assetData !== null" class="box price-data column is-two-thirds-tablet is-one-third-fullhd is-half-desktop">
          <div class="dropdown is-hoverable more-menu">
            <div class="dropdown-trigger">
              <div class="menu-wrapper">
                <img src="../assets/more.svg" />
              </div>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a
                    :class="{'is-disabled': this.$store.wallet !== null}"
                    @click="$eventBus.$emit('connect-request')"
                    class="dropdown-item"
                >
                  Connect Wallet
                </a>
                <a
                    @click="submitPricesModalActive = true"
                    :class="{'is-disabled': this.$store.wallet === null || this.$store.oracleData === null}"
                    class="dropdown-item"
                >
                  Update Price
                </a>
              </div>
            </div>
          </div>

          <h1 class="title has-text-centered">
            <a target="_blank" rel="noopener" :href="coinbaseLink">Coinbase Pro</a>
          </h1>
          <h2 class="subtitle has-text-centered is-6">
            Last Updated: ~{{ humanFormat(mostRecentPair.lastUpdateTime) }} Ago
          </h2>
          <table class="table is-hoverable is-fullwidth">
            <tbody>
              <tr class="pair-info" :key="pair[0]" v-for="pair in sorted(assetData)">
                <td class="has-text-centered">
                  <span><img :src="iconURL(pair[0])" /></span>
                </td>
                <td class="has-text-centered">
                  <p class="ticker">{{ pair[0] }}</p>
                </td>
                <td class="has-text-centered">
                  <p class="price">${{ numberWithCommas((pair[1].computedPrice / 1000000).toFixed(2)) }}</p>
                </td>
                <td class="has-text-right">

                  <sparkline v-if="sparkData(pair[1]) !== null" :tooltipProps="tooltipProps">
                    <sparklineCurve
                        :styles="{stroke: strokeColor(pair[1])}"
                        :refLineType="false" :refLineStyles="{}"
                        :data="sparkData(pair[1])"
                        :limit="pair[1].prices.saved.length"
                    />
                  </sparkline>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="box data-loading column is-half-desktop is-flex is-align-items-center is-justify-content-center is-two-thirds-tablet is-one-third-fullhd is-half-desktop">
          <div class="loader is-medium is-primary"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { MichelsonMap } from '@taquito/taquito'
import { TezosLanguageUtil } from 'conseiljs'

import moment from 'moment'
import _ from "lodash";
import WalletConnector from "@/components/WalletConnector";
import PendingTx from "@/components/PendingTx";

export default {
  name: 'Index',
  components: {WalletConnector, PendingTx},
  async mounted(){
    this.normalizerContract = await this.$store.tezosToolkit.contract.at(this.$store.configs[this.$store.network].normalizerContract)
    this.oracleContract = await this.$store.tezosToolkit.contract.at(this.$store.configs[this.$store.network].oracleContract)

    this.normalizerStorage = await this.normalizerContract.storage()
    this.oracleContractStorage = await this.oracleContract.storage()

    if (this.$store.oracleData === null){
      this.$eventBus.$on('oracle-init', async () => {
        console.log("firing price update forever!")
        await this.priceUpdateForever()
      })
    } else {
      await this.priceUpdateForever()
    }

    setInterval(() => {
      this.currentTime = moment(new Date())
    }, 1000)
  },
  methods: {
    async priceUpdateForever(){
      await this.updatePriceData()
      setTimeout(() => {
        console.log("Firing priceUpdateForever timeout")
        this.priceUpdateForever()
      }, 30 * 1000)
    },
    async updatePriceData(){
      this.assetData = Object.assign(
          {},
          ...await this.getAssetData(this.normalizerStorage)
      );
      this.latestOracleUpdateTimes = Object.assign(
          {},
          ...await Promise.all(this.normalizerStorage.assetCodes.map(async (code) => {
            const pairData = await this.oracleContractStorage.oracleData.get(code)
            return {[code]: pairData[0]}
          }))
      )
      console.log("update times", this.latestOracleUpdateTimes)
    },
    async updatePrices(){
      this.networkLoading = true

      const contract = await this.$store.tezosToolkit.wallet.at(this.$store.configs[this.$store.network].oracleContract)
      const normalizer = this.$store.configs[this.$store.network].normalizerContract

      try {
        const opResult = await this.$store.tezosToolkit.wallet
          .batch([])
          .withContractCall(contract.methods.update(this.oracleUpdateRequest))
          .withContractCall(contract.methods.push(normalizer + "%update"))
          .send()

        this.$store.pendingTx = {
          type: "Update",
          raw: opResult
        }

        this.networkLoading = false
        console.log("Submitted oracleUpdateRequest " + this.$store.pendingTx.opHash)

        console.log("Closing modal")
        this.submitPricesModalActive = false

        await opResult.confirmation(1)

        await this.updatePriceData()
      } catch (e) {
        this.networkLoading = false
        console.log(e)
        this.$swal("Error updating prices", JSON.stringify(e), 'error');
      } finally {
        console.log("Done!")
        this.$store.pendingTx = null
      }

    },
    async getAssetData(normalizerStorage){
      console.log("Fetching asset data")
      return Promise.all(
          normalizerStorage.assetCodes.map(async (code) => {
            const pairData = await normalizerStorage.assetMap.get(code)
            pairData.prices.saved = Array.from(pairData.prices.saved.valueMap.values())
            pairData.volumes.saved = Array.from(pairData.volumes.saved.valueMap.values())
            return {[code]: pairData}
          })
      )
    },
    async checkForUpdate(normalizerStorage, oracleContractStorage){
      console.log("Checking for update")
      const xtzData = await oracleContractStorage.oracleData.get("XTZ-USD")
      if (xtzData.lastUpdateTime !== this.assetData['XTZ-USD'].lastUpdateTime){
        console.log("Time to update!")
        await this.fireUpdate(normalizerStorage, oracleContractStorage)
      }

      setTimeout(() => {
        console.log("Firing checkForUpdate timeout")
        this.checkForUpdate(normalizerStorage, oracleContractStorage)
      }, 30 * 1000)
    },
    iconURL(ticker) {
      const images = require.context('../assets/', false, /\.png$/)
      return images(`./${ticker}.png`)
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    strokeColor(pair) {
      const previousPrices = this.previousPrices(pair)
      if (previousPrices[0] < previousPrices[previousPrices.length - 1]) {
        return '#00d1b2'
      }
      return '#ff3860'
    },
    sparkData(pair) {
      return this.previousPrices(pair)
    },
    previousPrices(pair) {
      return pair.prices.saved.map((price, index) => price / pair.volumes.saved[index])
    },
    sorted(payload) {
      return _(payload)
          .toPairs()
          .sortBy(1, (item) => item[1].computedPrice.toNumber())
          .reverse()
          .value()
    },
    humanFormat(date) {
      const age = moment.duration(this.currentTime - moment(date))
      return age.humanize()
    },
    humanFormatEpoch(timestamp) {
      const age = moment.duration(this.currentTime - moment.unix(timestamp))
      // console.log("humanFormatEpoch", moment.unix(timestamp).toString(), this.currentTime.toString(), age.humanize())
      return age.humanize()
    },
  },
  computed: {
    oracleDataDisplay(){
      if (Object.keys(this.oracleUpdateRaw.formattedRequest).length === 0){
        return "No update possible (the oracle is already up-to-date or there is no new data from the coinbase API)!"
      } else {
        return JSON.stringify(this.oracleUpdateRaw.formattedRequest, null, 2)
      }
    },
    coinbaseLink(){
      let normalizer, network
      if (this.$store.isTestnet){
        normalizer = this.$store.configs.testnet.normalizerContract
        network = 'edo2net'
      } else {
        normalizer = this.$store.configs.mainnet.normalizerContract
        network = 'mainnet'
      }
      return `https://better-call.dev/${network}/${normalizer}/operations`
    },
    mostRecentPair(){
      return _.orderBy(this.assetData, (assetData) => {
        return moment(assetData.lastUpdateTime).unix()
      }, 'desc')[0]
    },
    oracleSignatures(){
      return this.$store.oracleData.signatures
    },
    oracleUpdateRaw(){
      let oracleBundles = _.zip(this.oracleMessages, this.oracleSignatures)

      oracleBundles = oracleBundles.reduce((acc, [message, signature]) => {
        let payload = message.split(' ')
        let assetName = payload[1].replaceAll('"', '')
        let start = payload[3]
        let end = payload[5]
        let open = payload[7]
        let high = payload[9]
        let low = payload[11]
        let close = payload[13]
        let volume = payload[14].replaceAll(')', '')

        // Only get our pairs that already exist in the oracle
        if (this.latestOracleUpdateTimes && this.latestOracleUpdateTimes[assetName] !== undefined){
          // Only try to update pairs that have data to update
          const latestUpdate = new Date(this.latestOracleUpdateTimes[assetName]).getTime() / 1000
          const startInt = parseInt(start)
          if (latestUpdate < startInt){
            acc['formattedRequest'][assetName] = { signature, start, end, open, high, low, close, volume }

            acc['michelsonMap'][assetName] = {
              0: signature, 1: start, 2: end,
              3: open, 4: high, 5: low,
              6: close, 7: volume
            }
          }
        }

        return acc
      }, {michelsonMap: {}, formattedRequest: {}})

      return oracleBundles
    },
    oracleUpdateRequest(){
      return new MichelsonMap.fromLiteral(this.oracleUpdateRaw.michelsonMap)
    },
    oracleMessages(){
      return this.$store.oracleData.messages.map((item) => {
        return TezosLanguageUtil.normalizeMichelsonWhiteSpace(TezosLanguageUtil.hexToMichelson(item.slice(2)).code);
      })
    },
    oracleMessagesParsed(){
      return this.$store.oracleMessages.map((code) => {
        return JSON.parse(TezosLanguageUtil.translateParameterMichelsonToMicheline(code))
      })
    }
  },
  data(){
    return {
      tezos: null,
      assetData: null,
      loading: true,
      submitPricesModalActive: false,
      networkLoading: false,
      currentTime: moment(new Date()),
      latestOracleUpdateTimes: null,
      oracleContract: null,
      oracleContractStorage: null,
      normalizerContract: null,
      normalizerContractStorage: null,
      tooltipProps: {
        formatter(val) {
          const value = (val.value / 1000000)
          return `$${Math.trunc(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${(value % 1).toString().substr(2, 4)}`
        },
      },
    }
  }
}
</script>

<style lang="scss">
  .index{
    .data-loading{
      min-height: 10rem;
    }
    .more-menu{
      position: absolute;
      top: 1rem;
      right: 1rem;
      .menu-wrapper{
        padding: 1rem 1rem 0;
        cursor: pointer;
        img{
          max-height: 1.25rem;
        }
      }
      .dropdown-item.is-disabled{
        pointer-events: none;
        opacity: .65;
      }
    }
    .price-data{
      position: relative;
      padding: 2rem;
      .pair-info{
        vertical-align: middle;
        td{
          padding: 0.5rem;
          vertical-align: middle;
          img{
            vertical-align: middle;
          }
        }
        .ticker{
          width: 100%;
        }
        .price{
          min-width: 3rem;
        }
        img{
          width: 2rem;
        }
      }
    }
  }
</style>
